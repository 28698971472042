<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Columns (Statuses) -->
      <div
        v-for="status in statuses"
        :key="status.slug"
        class="col-md-3"
      >
        <div class="card h-100">
          <div class="card-header d-flex justify-content-between">
            <span>
              {{ status.title }}
            </span>
            <button
              @click="openAddTaskForm(status.id)"
              class="btn btn-sm btn-primary"
            >
              Add Task
            </button>
          </div>
          <div class="bg-blue-100">
            <!-- AddTaskForm -->
            <AddTaskForm
              v-if="newTaskForStatus === status.id"
              :status-id="status.id"
              v-on:task-added="handleTaskAdded"
              v-on:task-canceled="closeAddTaskForm"
            />
            <!-- ./AddTaskForm -->
  
            <!-- Tasks -->
            <draggable v-model="status.tasks"
                       transition="100"
                       class="drop-zone"
                       @update:model-value="handleTaskMoved"
            >
              <template v-slot:item="{item}">
                <div @click="showTaskDetails(item.id)">
                  <span class="draggable-item">
                    {{ item.title }}
                  </span>
                </div>
              </template>
              <!-- ./Tasks -->
            </draggable>
          </div>
        </div>
      </div>
      <!-- ./Columns -->
    </div>
  </div>

  <!-- Modal -->
  <div v-if="taskDetails" class="modal fade show d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ taskDetailsData?.title }}</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="accordion" id="accordionTask">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseDetails" aria-expanded="true" aria-controls="collapseDetails">
                      Details
                    </button>
                  </h2>
                </div>

                <div id="collapseDetails" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionTask">
                  <div class="card-body">
                    
                  </div>
                </div>
              </div>
            </div>
            <p>{{ taskDetailsData?.description }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Backdrop -->
    <div v-if="taskDetails" class="modal-backdrop fade show"></div>
  </template>
  
  <script>
  import Draggable from "vue3-draggable";
  import AddTaskForm from "./AddTaskForm";
  
  export default {
    components: { Draggable, AddTaskForm },
    props: {
      initialData: Array
    },
    data() {
      return {
        statuses: [],
        newTaskForStatus: 0,
        taskDetails: false,
        currentTaskId: null,
        taskDetailsData: null
      };
    },
    computed: {

    },
    mounted() {
      this.statuses = JSON.parse(JSON.stringify(this.initialData));
    },
    methods: {
      openAddTaskForm(statusId) {
        this.newTaskForStatus = statusId;
      },
      closeAddTaskForm() {
        this.newTaskForStatus = 0;
      },
      handleTaskAdded(newTask) {
        const statusIndex = this.statuses.findIndex(
          status => status.id === newTask.status_id
        );
  
        this.statuses[statusIndex].tasks.push(newTask);
  
        this.closeAddTaskForm();
      },
      handleTaskMoved() {
        axios.put("/tasks/sync", { columns: this.statuses }).catch(err => {
          console.log(err.response);
        });
      },
      showTaskDetails(id) {
      this.currentTaskId = id;
      console.log(id);

      axios.get(`/api/v1/task/${id}`)
        .then(response => {
          this.taskDetailsData = response.data;
          this.taskDetails = true;
          console.log(response.data);
        })
        .catch(error => {
          console.error("There was an error fetching the task details:", error);
        });
    },
    closeModal() {
      this.taskDetails = false;
      this.currentTaskId = null;
    },
    }
  };
  </script>

  <style>
  .draggable-item {
    display: flex;
    justify-content: center;
    background-color: lightsteelblue;
    box-shadow: 0px 2px 5px #aaa;
    margin: 1%;
    padding: 1%;
  }
  .drop-zone {
    display: flex;
    flex-direction: column;
    
    padding: 10px;
  }
  pre {
    background-color: #eee;
    margin: 30px;
    padding: 10px;
    width: 400px;
    min-height: 200px;
  }
  </style>